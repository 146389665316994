import * as React from 'react'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'

const PreviewPage = () => {
	return <p>Loading preview</p>
}

export default withPrismicPreviewResolver(PreviewPage, [{
	repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY,
	linkResolver,
  htmlSerializer,
}])
